import './App.css';
import Index from './Routes/Index';
import 'react-toastify/dist/ReactToastify.css';
import { PrimeReactProvider } from 'primereact/api';

function App() {
  return (
    <>
     <PrimeReactProvider>
      <Index />
     </PrimeReactProvider>
    </>
  );
}

export default App;
