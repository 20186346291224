import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import Loading from "../../Utils/Loading";

// Lazy load client pages
const HomePage = React.lazy(() => import("../../pages/client/Index"));
const AboutPage = React.lazy(() => import("../../pages/client/AboutUs"));
const ServicesPage = React.lazy(() => import("../../pages/client/Services"));
const CarsPage = React.lazy(() => import("../../pages/client/CarsPage"));
const CarDetails = React.lazy(() => import("../../pages/client/CarDetails"));
const ContactPage = React.lazy(() => import("../../pages/client/Contact"));
const TermsOfUse = React.lazy(() =>
  import("../../pages/client/TermAndCondition")
);
const TouristPlaceDetail = React.lazy(() =>
  import("../../pages/client/TouristPlaceDetail")
);
const Dashboard = React.lazy(() => import("../../pages/admin/Dashboard")); // Admin dashboard
const NotFound = React.lazy(() => import("../../pages/client/404/NotFound"));
const PrivacyPolicy = React.lazy(() =>
  import("../../pages/client/PrivacyPolicy")
);

function ClientRoutes() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {/* Home Page */}
        <Route path="/" element={<HomePage />} />

        {/* About Page */}
        <Route path="/about" element={<AboutPage />} />

        {/* Services Page */}
        <Route path="/services" element={<ServicesPage />} />

        {/* Cars Page */}
        <Route path="/cars" element={<CarsPage />} />

        {/* Car Details Page */}
        <Route path="/cardetails" element={<CarDetails />} />

        {/* Contact Page */}
        <Route path="/contact" element={<ContactPage />} />

        {/* Terms and Condition Page */}
        <Route path="/terms-of-use" element={<TermsOfUse />} />

        {/* Admin Route */}
        <Route path="/admin-dashboard" element={<Dashboard />} />

        {/* Privacy Policy */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Tourist Place Details */}
        <Route path="/placedetails" element={<TouristPlaceDetail />} />

        {/* 404 Page */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default ClientRoutes;
