import {  Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import Loading from '../../Utils/Loading';
import AdminPageGuard from '../../shared/AdminPageGuard';
import AdminAuthGuard from '../../shared/AdminAuthGuard';

const Singin = React.lazy(() => import('../../pages/admin/Auth/Singin'));

const SendEmail = React.lazy(() => import('../../pages/admin/Auth/SendEmail'));

const ForgetPassword = React.lazy(() => import('../../pages/admin/Auth/ForgetPassword'));
const Dashboard = React.lazy(() => import('../../pages/admin/Dashboard'));

const Settings = React.lazy(() => import('../../pages/admin/Settings/Settings'));

const Bookings = React.lazy(() => import('../../pages/admin/Bookings/Bookings'));
const EditBooking = React.lazy(() => import('../../pages/admin/Bookings/EditBooking'));
const Vehicle = React.lazy(() => import('../../pages/admin/Vehicle/Vehicle'));
const AddVehicle = React.lazy(() => import('../../pages/admin/Vehicle/AddVehicle'));
const EditVehicle = React.lazy(() => import('../../pages/admin/Vehicle/EditVehicle'));

const Services = React.lazy(() => import('../../pages/admin/Service/Services'));
const AddService = React.lazy(() => import('../../pages/admin/Service/AddService'));
const EditService = React.lazy(() => import('../../pages/admin/Service/EditService'));

const Reviews = React.lazy(() => import('../../pages/admin/Review/Reviews'));
const AddReview = React.lazy(() => import('../../pages/admin/Review/AddReview'));
const EditReview = React.lazy(() => import('../../pages/admin/Review/EditReview'));

const Blogs = React.lazy(() => import('../../pages/admin/Blogs/Blogs'));
const AddBlog = React.lazy(() => import('../../pages/admin/Blogs/AddBlog'));
const EditBlog = React.lazy(() => import('../../pages/admin/Blogs/EditBlog'));

const Utils = React.lazy(() => import('../../pages/admin/Utils/Utils'));
const EditUtils = React.lazy(() => import('../../pages/admin/Utils/EditUtils'));

const NotFound = React.lazy(() => import('../../pages/admin/404/NotFound'));

function AdminRoutes() {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* Auth */}
          <Route
            path="/signin"
            element={
              <AdminPageGuard>
                <Singin />
              </AdminPageGuard>
            }
          />
          <Route
            path="/forgetpassword/:token"
            element={
              <AdminPageGuard>
                <ForgetPassword />
              </AdminPageGuard>
            }
          />
          <Route
            path="/sendemail"
            element={
              <AdminPageGuard>
                <SendEmail />
              </AdminPageGuard>
            }
          />

          {/* Dashboard */}
          <Route path="/" element={<AdminAuthGuard ><Dashboard/></AdminAuthGuard>} />
          <Route
            path="/dashboard"
            element={
              <AdminAuthGuard>
                <Dashboard />
              </AdminAuthGuard>
            }
          />

          {/* Settings */}
          <Route
            path="/settings"
            element={
              <AdminAuthGuard>
                <Settings />
              </AdminAuthGuard>
            }
          />

          {/* Bookings */}
          <Route
            path="/booking"
            element={
              <AdminAuthGuard>
                <Bookings />
              </AdminAuthGuard>
            }
          />
          <Route
            path="/booking/edit/:id"
            element={
              <AdminAuthGuard>
                <EditBooking />
              </AdminAuthGuard>
            }
          />

          {/* Vehicles */}
          <Route
            path="/vehicle"
            element={
              <AdminAuthGuard>
                <Vehicle />
              </AdminAuthGuard>
            }
          />
          <Route
            path="/vehicle/add"
            element={
              <AdminAuthGuard>
                <AddVehicle />
              </AdminAuthGuard>
            }
          />
          <Route
            path="/vehicle/edit/:id"
            element={
              <AdminAuthGuard>
                <EditVehicle />
              </AdminAuthGuard>
            }
          />

          {/* Services */}
          <Route
            path="/service"
            element={
              <AdminAuthGuard>
                <Services />
              </AdminAuthGuard>
            }
          />
          <Route
            path="/service/add"
            element={
              <AdminAuthGuard>
                <AddService />
              </AdminAuthGuard>
            }
          />
          <Route
            path="/service/edit/:id"
            element={
              <AdminAuthGuard>
                <EditService />
              </AdminAuthGuard>
            }
          />

          {/* Reviews */}
          <Route
            path="/review"
            element={
              <AdminAuthGuard>
                <Reviews />
              </AdminAuthGuard>
            }
          />
          <Route
            path="/review/add"
            element={
              <AdminAuthGuard>
                <AddReview />
              </AdminAuthGuard>
            }
          />
          <Route
            path="/review/edit/:id"
            element={
              <AdminAuthGuard>
                <EditReview />
              </AdminAuthGuard>
            }
          />

          {/* Utils */}
          <Route
            path="/util"
            element={
              <AdminAuthGuard>
                <Utils />
              </AdminAuthGuard>
            }
          />
          <Route
            path="/util/edit/:id"
            element={
              <AdminAuthGuard>
                <EditUtils />
              </AdminAuthGuard>
            }
          />

          {/* Blog */}
          <Route
            path="/blog"
            element={
              <AdminAuthGuard>
                <Blogs />
              </AdminAuthGuard>
            }
          />
          <Route
            path="/blog/add"
            element={
              <AdminAuthGuard>
                <AddBlog />
              </AdminAuthGuard>
            }
          />
          <Route
            path="/blog/edit/:id"
            element={
              <AdminAuthGuard>
                <EditBlog />
              </AdminAuthGuard>
            }
          />

          {/* 404 */}
          <Route
            path="*"
            element={
              <AdminAuthGuard>
                <NotFound />
              </AdminAuthGuard>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
}

export default AdminRoutes;
